import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoSportsApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl + "/offer/",
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    changeFixtureDate(fixtureId, newDate) {
        var changeObj = {
            FixtureId: fixtureId,
            Newdate: newDate
        }
        return this.Api.post('changeFixtureDate', changeObj);
    }

    saveResultsDetails(fixtureId, changedOutcomes, homeScore, awayScore, voided, active, resultsChanged, fixturesActive) {
        var fixtureObj = {
            FixtureId: fixtureId,
            Changes: changedOutcomes,
            HomeScore: homeScore == "" ? 0 : homeScore,
            AwayScore: awayScore == "" ? 0 : awayScore,
            IsVoided: voided,
            IsActivated: active,
            NewScore: resultsChanged,
            IsOutright: !fixturesActive
        }
        return this.Api.post('saveResults', fixtureObj);
    }
    saveFixtureDetails(fixtureId, inOffer, changedOutcomes, addedMarkets, removedMarkets, isTopOffer, isOutright) {
        var fixtureObj = {
            FixtureId: fixtureId,
            InOffer: inOffer,
            Changes: changedOutcomes,
            AddedMarkets: addedMarkets,
            RemovedMarkets: removedMarkets,
            IsTopOffer: isTopOffer,
            IsOutright: isOutright
        }
        return this.Api.post('saveFixture', fixtureObj);
    }
    backToAutoOdds(fixtureId, isOutright) {
        return this.Api.get('backToAuto/' + fixtureId + "/" + isOutright);
    }
    addSpecialMarket(markets, fixtureId) {
        var fixtureObj = {
            FixtureId: fixtureId,
            NewMarkets: markets
        }
        return this.Api.post('addSpecialMarket', fixtureObj);
    }
    publishOffer() {
        return this.Api.get('publishShopOffer');
    }
    addNewEvent(event) {
        return this.Api.post("addNewFixture", event);
    }
    updatePrintSelection(fixtureId, newStatus) {
        return this.Api.get('UpdatePrintSelectionOffer/' + fixtureId + '/' + newStatus);
      }
}

export {
    BoSportsApi
}
