<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.createEvent') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <div class="p-col-12">
                            <span class="p-float-label">
                                <Dropdown id="dropdown" style="width: 100%" v-model="eventTypeModel" :options="eventTypes" optionLabel="name" />
                                <label for="dropdown">{{ $t('dropdown.placeholder.eventType') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12" v-show="showFixtureId">
                            <span class="p-float-label">
                                <InputText id="newOdd" style="width: 100%" type="text" v-model="fixtureId" />
                                <label for="newOdd">{{ $t('sportsbets.fixtureId') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12">
                            <span class="p-float-label">
                                <InputText id="competitor1" style="width: 100%" type="text" v-model="competitor1" />
                                <label for="competitor1">{{ $t('sportsbets.homeId') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12">
                            <span class="p-float-label">
                                <InputText id="competitor2" style="width: 100%" type="text" v-model="competitor2" />
                                <label for="competitor2">{{ $t('sportsbets.awayId') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showIcon="true" :showTime="true" :showSeconds="true" timeFormat="hh:mm:ss" :showButtonBar="true" v-model="dateModel"></Calendar>
                        </div>
                        <div class="p-col-12">
                            <Dropdown style="width: 100%" v-model="sportModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                        </div>
                        <div class="p-col-12">
                            <Dropdown style="width: 100%" v-model="categoryModel" v-show="showCategoryDdl" :options="categories" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.category')" :filter="!this.isMobile()" />
                        </div>
                        <div class="p-col-12">
                            <Dropdown style="width: 100%" v-model="tournamentModel" v-show="showTournamentsDdl" :options="tournaments" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.tournament')" :filter="!this.isMobile()" />
                        </div>
                    </div>
                    <div class="p-col-12 p-md-8">
                        <div class="p-mt-2" v-show="this.eventTypeModel.id != 2">
                            <div class="p-grid">
                                <div class="p-col-6 p-md-2">
                                    <span class="p-float-label">
                                        <Dropdown id="dropdownPayout" style="width: 100%" v-model="payoutModel" :options="payoutValues" optionLabel="value" />
                                        <label for="dropdownPayout">{{ $t('dropdown.placeholder.payout') }}</label>
                                    </span>
                                </div>
                                <div class="p-col-6 p-md-offset-8 p-md-2 p-text-right">
                                    <SelectButton v-show="!isFootball" v-model="sportOutcomesNo" optionLabel="label" :options="toggleOptions" />
                                </div>
                            </div>
                            <div v-show="isFootball">
                                <div class="card">
                                    <h6>{{ $t('sportsbets.markets.final') }}</h6>
                                    <div class="p-col-12 p-grid">
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="odd1" @keydown="blockOddInput('1')" />
                                                <label>{{ $t('sportsbets.odd') + ' 1' }}</label>
                                            </span>
                                        </div>
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="oddx" @keydown="blockOddInput('x')" />
                                                <label>{{ $t('sportsbets.odd') + ' X' }}</label>
                                            </span>
                                        </div>
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="odd2" @keydown="blockOddInput('2')" />
                                                <label>{{ $t('sportsbets.odd') + ' 2' }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <h6>{{ $t('sportsbets.markets.sansaDubla') }}</h6>
                                    <div class="p-col-12 p-grid">
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="odd1x" disabled />
                                                <label>{{ $t('sportsbets.odd') + ' 1X' }}</label>
                                            </span>
                                        </div>
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="oddx2" disabled />
                                                <label>{{ $t('sportsbets.odd') + ' X2' }}</label>
                                            </span>
                                        </div>
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="odd12" disabled />
                                                <label>{{ $t('sportsbets.odd') + ' 12' }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <h6>{{ $t('sportsbets.markets.underOver') }}</h6>
                                    <div class="p-col-12 p-grid">
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="oddunder" @keydown="blockOddInput('under')" />
                                                <label>{{ $t('sportsbets.odd') + ' ' + $t('sportsbets.oddType.under') }}</label>
                                            </span>
                                        </div>
                                        <div class="p-offset-4 p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="oddover" @keydown="blockOddInput('over')" />
                                                <label>{{ $t('sportsbets.odd') + ' ' + $t('sportsbets.oddType.over') }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="!isFootball">
                                <div class="card" v-show="sportOutcomesNo.value == 2">
                                    <h6>{{ $t('sportsbets.markets.final') }}</h6>
                                    <div class="p-col-12 p-grid">
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="odd1_2way" @keydown="blockOddInput('1_2way')" />
                                                <label>{{ $t('sportsbets.odd') + ' 1' }}</label>
                                            </span>
                                        </div>
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="odd2_2way" @keydown="blockOddInput('2_2way')" />
                                                <label>{{ $t('sportsbets.odd') + ' 2' }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card" v-show="sportOutcomesNo.value == 3">
                                    <h6>{{ $t('sportsbets.markets.final') }}</h6>
                                    <div class="p-col-12 p-grid">
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="odd1_3way" @keydown="blockOddInput('1_3way')" />
                                                <label>{{ $t('sportsbets.odd') + ' 1' }}</label>
                                            </span>
                                        </div>
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="oddx_3way" @keydown="blockOddInput('x_3way')" />
                                                <label>{{ $t('sportsbets.odd') + ' X' }}</label>
                                            </span>
                                        </div>
                                        <div class="p-col-4">
                                            <span class="p-float-label">
                                                <InputText style="width: 100%" type="text" v-model="odd2_3way" @keydown="blockOddInput('2_3way')" />
                                                <label>{{ $t('sportsbets.odd') + ' 2' }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button :label="$t('buttons.addEvent')" class="p-button-raised p-button-success" @click="addFixture()" />
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { BoSportsApi } from '../../service/BoSportsApi';

export default {
    name: 'edit',
    data() {
        return {
            disableSubmit: false,
            BoSportsApi: new BoSportsApi(),
            SportsSettingsApi: new SportsSettingsApi(),
            showOddsContent: true,
            sports: [],
            categories: [],
            tournaments: [],
            sportModel: {},
            categoryModel: {},
            tournamentModel: {},
            dateModel: {},
            showSportDdl: false,
            showCategoryDdl: false,
            showTournamentsDdl: false,
            fixtureId: '',
            competitor1: '',
            competitor2: '',
            isBRFixture: true,
            eventTypeModel: {
                id: 0,
                name: this.$t('sportsbets.newFixtureChoice'),
            },
            showFixtureId: false,
            payoutModel: { id: 8, value: '8%' },
            payoutValues: [
                { id: 0, value: '0%' },
                { id: 1, value: '1%' },
                { id: 2, value: '2%' },
                { id: 3, value: '3%' },
                { id: 4, value: '4%' },
                { id: 5, value: '5%' },
                { id: 6, value: '6%' },
                { id: 7, value: '7%' },
                { id: 8, value: '8%' },
                { id: 9, value: '9%' },
                { id: 10, value: '10%' },
                { id: 11, value: '11%' },
                { id: 12, value: '12%' },
                { id: 13, value: '13%' },
                { id: 14, value: '14%' },
                { id: 15, value: '15%' },
                { id: 16, value: '16%' },
                { id: 17, value: '17%' },
                { id: 18, value: '18%' },
                { id: 19, value: '19%' },
                { id: 20, value: '20%' },
            ],
            odd1: '',
            oddx: '',
            odd2: '',
            odd12: '',
            odd1x: '',
            oddx2: '',
            oddunder: '',
            oddover: '',
            odd1_3way: '',
            oddx_3way: '',
            odd2_3way: '',
            odd1_2way: '',
            odd2_2way: '',
            odd1blocked: false,
            oddxblocked: false,
            odd2blocked: false,
            oddunderblocked: false,
            oddoverblocked: false,
            odd1_3wayblocked: false,
            oddx_3wayblocked: false,
            odd2_3wayblocked: false,
            odd1_2wayblocked: false,
            odd2_2wayblocked: false,
            showOkNotification: false,
            is2WaySport: false,
            is3WaySport: false,
            toggleOptions: [
                { value: 2, label: '2 Way' },
                { value: 3, label: '3 Way' },
            ],
            sportOutcomesNo: 2,
        };
    },
    created() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        this.dateModel = new Date(startDateString);
        this.getSports();
    },
    watch: {
        sportModel() {
            if (this.notNullOrEmptyObject(this.sportModel)) {
                this.getCategories();
            }
        },
        categoryModel() {
            if (this.notNullOrEmptyObject(this.categoryModel)) {
                this.getTournaments();
            }
        },
        eventTypeModel() {
            if (this.eventTypeModel.id == 1) {
                this.showFixtureId = true;
            } else {
                this.showFixtureId = false;
            }
        },
        odd1() {
            if (this.oddx != '' && !this.odd2blocked) {
                this.odd2 = this.compute3WayOdd(this.odd1, this.oddx);
                this.computeDoubleChanceOdds();
            } else {
                if (this.odd2 != '' && !this.oddxblocked) {
                    this.oddx = this.compute3WayOdd(this.odd1, this.odd2);
                    this.computeDoubleChanceOdds();
                }
            }
        },
        oddx() {
            if (this.odd1 != '' && !this.odd2blocked) {
                this.odd2 = this.compute3WayOdd(this.odd1, this.oddx);
                this.computeDoubleChanceOdds();
            } else {
                if (this.odd2 != '' && !this.odd1blocked) {
                    this.odd1 = this.compute3WayOdd(this.oddx, this.odd2);
                    this.computeDoubleChanceOdds();
                }
            }
        },
        odd2() {
            if (this.oddx != '' && !this.odd1blocked) {
                this.odd1 = this.compute3WayOdd(this.odd2, this.oddx);
                this.computeDoubleChanceOdds();
            } else {
                if (this.odd1 != '' && !this.oddxblocked) {
                    this.oddx = this.compute3WayOdd(this.odd1, this.odd2);
                    this.computeDoubleChanceOdds();
                }
            }
        },
        odd1_3way() {
            if (this.oddx_3way != '' && !this.odd2_3wayblocked) {
                this.odd2_3way = this.compute3WayOdd(this.odd1_3way, this.oddx_3way);
            } else {
                if (this.odd2_3way != '' && !this.oddx_3wayblocked) {
                    this.oddx_3way = this.compute3WayOdd(this.odd1_3way, this.odd2_3way);
                }
            }
        },
        oddx_3way() {
            if (this.odd1_3way != '' && !this.odd2_3wayblocked) {
                this.odd2_3way = this.compute3WayOdd(this.odd1_3way, this.oddx_3way);
            } else {
                if (this.odd2_3way != '' && !this.odd1_3wayblocked) {
                    this.odd1_3way = this.compute3WayOdd(this.oddx_3way, this.odd2_3way);
                }
            }
        },
        odd2_3way() {
            if (this.oddx_3way != '' && !this.odd1_3wayblocked) {
                this.odd1_3way = this.compute3WayOdd(this.odd2_3way, this.oddx_3way);
            } else {
                if (this.odd1_3way != '' && !this.oddx_3wayblocked) {
                    this.oddx_3way = this.compute3WayOdd(this.odd1_3way, this.odd2_3way);
                }
            }
        },
        oddunder() {
            if (!this.oddoverblocked) {
                this.oddover = this.compute2WayOdd(this.oddunder);
            }
        },
        oddover() {
            if (!this.oddunderblocked) {
                this.oddunder = this.compute2WayOdd(this.oddover);
            }
        },
        odd1_2way() {
            if (!this.odd2_2way) {
                this.odd2_2way = this.compute2WayOdd(this.odd1_2way);
            }
        },
        odd2_2way() {
            if (!this.odd1_2wayblocked) {
                this.odd1_2wayunder = this.compute2WayOdd(this.odd2_2way);
            }
        },
        payoutModel() {
            this.clearOddsValues();
        },
    },
    methods: {
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        getSports() {
            this.showSportFiltersLoading = true;
            this.showSportDdl = false;
            this.SportsSettingsApi.getAllSports()
                .then((response) => {
                    this.sports = response.data;
                    this.showSportDdl = true;
                    this.showTournamentsDdl = false;
                    this.showCategoryDdl = false;
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        getCategories() {
            this.showCategoryFiltersLoading = true;
            this.showCategoryDdl = false;
            this.SportsSettingsApi.getAllCategories(this.sportModel.id)
                .then((response) => {
                    this.categories = response.data;
                    this.showCategoryDdl = true;
                    this.showTournamentsDdl = false;
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        getTournaments() {
            this.showTournamentFiltersLoading = true;
            this.SportsSettingsApi.getAllTournaments(this.sportModel.id, this.categoryModel.id)
                .then((response) => {
                    this.tournaments = response.data;
                    this.showTournamentsDdl = true;
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        clearOddsValues() {
            this.odd1 = '';
            this.odd1blocked = false;
            this.oddx = '';
            this.oddxblocked = false;
            this.odd2 = '';
            this.odd2blocked = false;
            this.odd1_3way = '';
            this.odd1_3wayblocked = false;
            this.oddx_3way = '';
            this.oddx_3wayblocked = false;
            this.odd2_3way = '';
            this.odd2_3wayblocked = false;
            this.odd1_2way = '';
            this.odd1_2wayblocked = false;
            this.odd2_2way = '';
            this.odd2_2wayblocked = false;
            this.odd1x = '';
            this.odd12 = '';
            this.oddx2 = '';
            this.oddunder = '';
            this.oddover = '';
            this.oddoverblocked = false;
            this.oddunderblocked = false;
        },
        clearEventDetails() {
            this.clearOddsValues();
            this.fixtureId = '';
            this.competitor1 = '';
            this.competitor2 = '';
            this.categoryModel = '';
            this.tournamentModel = '';
            this.tournaments = [];
            this.showTournamentsDdl = false;
        },
        addFixture() {
            if (
                this.notNullOrEmptyField(this.competitor1) &&
                this.notNullOrEmptyField(this.competitor2) &&
                this.notNullOrEmptyField(this.dateModel) &&
                this.notNullOrEmptyObject(this.sportModel) &&
                this.notNullOrEmptyObject(this.categoryModel) &&
                this.notNullOrEmptyObject(this.tournamentModel.id) &&
                this.notNullOrEmptyObject(this.eventTypeModel)
            ) {
                if (this.eventTypeModel.id == 2) {
                    this.saveFixture();
                } else {
                    if (this.eventTypeModel.id != 1 || (this.eventTypeModel.id == 1 && this.notNullOrEmptyField(this.fixtureId))) {
                        if (this.notNullOrEmptyField(this.payoutModel)) {
                            if (
                                (this.notNullOrEmptyField(this.odd1) && this.notNullOrEmptyField(this.oddx) && this.notNullOrEmptyField(this.odd2)) ||
                                (this.notNullOrEmptyField(this.odd1_3way) && this.notNullOrEmptyField(this.oddx_3way) && this.notNullOrEmptyField(this.odd2_3way)) ||
                                (this.notNullOrEmptyField(this.odd1_2way) && this.notNullOrEmptyField(this.odd2_2way))
                            ) {
                                if (!this.isFootball || (this.isFootball && this.notNullOrEmptyField(this.oddunder) && this.notNullOrEmptyField(this.oddover))) {
                                    this.saveFixture();
                                } else {
                                    this.showToast('error', this.$t('sportsbets.alerts.addFixtureUnderOverError'));
                                }
                            } else {
                                this.showToast('error', this.$t('sportsbets.alerts.addFixtureFinalError'));
                            }
                        } else {
                            this.showToast('error', this.$t('sportsbets.alerts.addFixturePayoutError'));
                        }
                    } else {
                        this.showToast('error', this.$t('sportsbets.alerts.addFixtureBetradarIDError'));
                    }
                }
            } else {
                this.showToast('error', this.$t('sportsbets.alerts.addFixtureEventError'));
            }
        },
        saveFixture() {
            let event = {
                Id: this.eventTypeModel.id == 1 ? this.fixtureId : '',
                Competitor1: this.competitor1,
                Competitor2: this.competitor2,
                EventDate: this.formatEnDateAndTime(this.dateModel),
                SportId: this.sportModel.id,
                CategoryId: this.categoryModel.id,
                TournamentId: this.tournamentModel.id,
                Odd1: this.odd1 != '' ? this.odd1 : 0,
                Odd2: this.odd2 != '' ? this.odd2 : 0,
                OddX: this.oddx != '' ? this.oddx : 0,
                Odd1X: this.odd1x != '' ? this.odd1x : 0,
                OddX2: this.oddx2 != '' ? this.oddx2 : 0,
                Odd12: this.odd12 != '' ? this.odd12 : 0,
                OddUnder: this.oddunder != '' ? this.oddunder : 0,
                OddOver: this.oddover != '' ? this.oddover : 0,
                Odd1_3way: this.odd1_3way != '' ? this.odd1_3way : 0,
                Odd2_3way: this.odd2_3way != '' ? this.odd2_3way : 0,
                OddX_3way: this.oddx_3way != '' ? this.oddx_3way : 0,
                Odd1_2way: this.odd1_2way != '' ? this.odd1_2way : 0,
                Odd2_2way: this.odd2_2way != '' ? this.odd2_2way : 0,
            };
            this.BoSportsApi.addNewEvent(event)
                .then(() => {
                    this.clearEventDetails();
                    this.showToast('success', this.$t('alerts.okAction'));
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    if (error.response && error.response.status === 404) {
                        this.showToast('error', this.$t('sportsbets.alerts.addFixtureCompetitorsError'));
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        compute2WayOdd(odd1) {
            return parseFloat(1 / (this.payoutTotal - 1 / odd1)).toFixed(2);
        },
        compute3WayOdd(odd1, odd2) {
            return parseFloat(1 / (this.payoutTotal - 1 / odd1 - 1 / odd2)).toFixed(2);
        },
        computeDoubleChanceOdds() {
            this.odd1x = this.compute2WayOdd(this.odd2);
            this.oddx2 = this.compute2WayOdd(this.odd1);
            this.odd12 = this.compute2WayOdd(this.oddx);
        },
        blockOddInput(oddCode) {
            switch (oddCode) {
                case '1': {
                    this.odd1blocked = true;
                    break;
                }
                case 'x': {
                    this.oddxblocked = true;
                    break;
                }
                case '2': {
                    this.odd2blocked = true;
                    break;
                }
                case '1_3way': {
                    this.odd1blocked = true;
                    this.odd1_3wayblocked = true;
                    break;
                }
                case 'x_3way': {
                    this.oddxblocked = true;
                    this.oddx_3wayblocked = true;
                    break;
                }
                case '2_3way': {
                    this.odd2blocked = true;
                    this.odd2_3wayblocked = true;
                    break;
                }
                case '1_2way': {
                    this.odd1blocked = true;
                    this.odd1_2wayblocked = true;
                    break;
                }
                case '2_2way': {
                    this.odd2blocked = true;
                    this.odd2_2wayblocked = true;
                    break;
                }
                case 'under': {
                    this.oddunderblocked = true;
                    break;
                }
                case 'over': {
                    this.oddoverblocked = true;
                    break;
                }
            }
        },
    },
    computed: {
        eventTypes() {
            let options = [
                {
                    id: 0,
                    name: this.$t('sportsbets.newFixtureChoice'),
                },
                {
                    id: 1,
                    name: this.$t('sportsbets.brFixtureChoice'),
                },
                {
                    id: 2,
                    name: this.$t('sportsbets.specialFixtureChoice'),
                },
            ];
            return options;
        },
        payoutTotal() {
            return parseFloat(1 + this.payoutModel.id / 100);
        },
        isFootball() {
            return this.notNullOrEmptyObject(this.sportModel) && this.sportModel.id == '1';
        },
    },
};
</script>